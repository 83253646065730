import React, { useState } from 'react'
import Header from './../components/layout/Header'
import Footer from './../components/layout/Footer'
import LoadingModal from './../components/modals/LoadingModal';
import LoginInfo from '../components/layout/LoginInfo';
import { Link, useNavigate } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';

export default function ClearStorage () {
  const navigate = useNavigate();
  const [storageCleared, setStorageCleared] = useState(false);

  function handleClearStorage() {
    localStorage.clear();
    sessionStorage.clear();

    setStorageCleared(true);

    setTimeout(() => {
      navigate('/login');
    }, 5000);
  }
    
  return (
    <section className="h-screen h-[calc(var(--vh)*100)] bg-gray-200 w-full text-white">
      <LoadingModal />
      <Header />
      <LoginInfo />
      <div className='w-full h-[calc(100%-7rem)] -mt-[3rem] flex items-start justify-center relative'>
        <article className='h-[calc(100%-3rem)] z-10 menu rounded-md bg-gray-100 w-full mx-4 shadow-lg max-w-[420px] md:max-w-screen-sm sm:mx-auto xl:max-w-screen-lg p-3 overflow-hidden'>
          <div className='h-[4rem] border-primary-color border-b-2 p-2 flex flex-row items-center justify-between'>
            <div>
              <h2 className='text-primary-color font-bold text-xl pt-2'>Limpar Armazenamento</h2>
              <h2 className='text-black text-xs pb-2'>CUIDADO! AO LIMPAR O ARMAZENAMENTO, TODOS OS DADOS OFF-LINE DO APLICATIVO SERÃO APAGADOS!</h2>
            </div>
            <Link to='/'>
              <BsArrowLeft className='text-primary-color text-4xl p-1 hover:scale-110 duration-150 hover:bg-primary-color hover:text-white rounded-full'/>        
            </Link>
          </div>
          <section className='h-[calc(100%-4rem)] overflow-auto menu-section text-black pt-[1rem] pb-[1rem] text-center menu'>
            <div className='h-full flex flex-col justify-between'>
              <div className='w-full flex flex-col items-center px-2 max-w-[768px] mx-auto'>
                <h2 className='text-black text-sm pb-4'>Ao apagar os dados, você precisa efetuar um novo login</h2>
                <article className='w-full py-2'>
                  <button
                    onClick={ () => handleClearStorage() }
                    className='h-[3rem] bg-white border-[2px] py-1 px-4 text-base rounded-md border-gray-400 text-primary-color hover:bg-primary-color hover:text-white'
                  >
                    Apagar Dados
                  </button>
                </article>
                <div>
                  {
                    storageCleared && (
                      <p className='text-red-500 text-sm mt-2'>Dados armazenados apagados com sucesso!</p>
                    )
                  }
                </div>
              </div>
            </div>
          </section>
        </article>
      </div>
      <Footer />
    </section>
  )
}